div.search {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
}
form {
  position: relative;
}
form svg:first-of-type {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
#closeArrow {
  position: absolute;
  display: none;
  /* opacity: 0; */
  top: 50%;
  transform: translateY(-50%);
  right: 28px;
  cursor: pointer;
}
input {
  /* padding: 5px; */
  width: 220px;
  outline: none;
  border: none;
  padding: 10px;
  font-style: italic;
}
