* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #292b2d;
}
.App {
  position: relative;
  font-family: sans-serif;
  text-align: center;
  min-height: 101vh;
  background-color: #292b2d;
  color: aliceblue;
}

h1 {
  color: #4ed1f7;
  padding: 15px;
  font-size: 2.5rem;
}
h2 {
  padding: 15px;
}

.loginBtn {
  color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-size: 0.9rem;
    margin: auto;
    color:#4ed1f7;
    background-color: #292b2d;
    border: solid 2px #4ed1f7;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom:  10px;
    border-radius: 9999px;
    transition: all ease-out 200ms;
}
.loginBtn:hover {
  color: white;
  border-color: white;
}
