ul {
  min-width: 40vw;
  max-width: 40vw;
  margin: 25px auto;
  margin-bottom: 20vh;
}
@media all and (max-width: 767px) {
  ul {
    min-width: 90vw;
    max-width: 90vw;
  }
}
@media all and (min-width: 767px) and (max-width: 1200px) {
  ul {
    min-width: 70vw;
    max-width: 70vw;
  }
}

::-moz-selection {
  color: inherit;
  background-color: inherit;
}

::selection {
  color: inherit;
  background-color: inherit;
}
li {
  display: flex;
  align-items: center;
  list-style-type: none;
  width: 100%;
  margin: 5px auto;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: capitalize;
  /* background-color: #4ed1f7; */
  color: #4ed1f7;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}
li img {
  min-width: 20px;
  max-width: 20px;
  margin-right: 15px;
  pointer-events: none; /* !!!! */
}
span {
  flex-grow: 1;
  text-align: right;
  margin-left: 10px;
  pointer-events: none;
}
