audio {
  width: 100%;
  outline: none;
}

.audioBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f1f3f4;
}
.audioWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 10vh;
  /* max-height: 10vh; */
}
.controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
#playerImg {
  max-height: 8vh;
  min-width: 15%;
  max-width: 15%;
  margin-left: 3vw;
}
div.title {
  width: 100%;
  min-width: 85%;
  max-width: 85%;
  overflow: hidden;
}
p.songTitle {
  text-align: center;
  color: #292b2d;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: capitalize;
}

@media all and (min-width: 1024px) {
  audio {
    max-width: 70%;
  }
  .audioWrap {
    max-width: 50%;
  }
}
@media all and (min-width: 769px) and (max-width:1023px) {
  audio {
    max-width: 90%;
  }
  .audioWrap {
    max-width: 70%;
  }
}
